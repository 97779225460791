<template>
<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
                <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                    Reset Password 🔒
                </b-card-title>
                <b-card-text class="mb-2">
                    Kindly enter your phone number to reset password
                </b-card-text>
                <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                    <div class="alert-body">
                        <span>{{errorMessage}}</span>
                    </div>
                </b-alert>
                <validation-observer ref="resetPassword">
                    <b-form class="auth-reset-password-form mt-2" method="POST" @submit.prevent="validationForm">

                        <b-form-group label="Phone Number" label-for="phone">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-form-select v-model="countryCode" :options="countryCodeOptions" />
                                </b-input-group-prepend>
                                <cleave id="phone" v-model="phone" class="form-control" :raw="false" :options="options.phone" placeholder="725560980" />
                            </b-input-group>

                        </b-form-group>

                        <!-- submit button -->
                        <b-button block type="submit" variant="primary" id="resetPWD">
                            <b-spinner small v-show="isLoading" />
                            Reset Password
                        </b-button>
                    </b-form>
                </validation-observer>

                <p class="text-center mt-2">
                    <b-link :to="{name:'login'}">
                        <feather-icon icon="ChevronLeftIcon" /> Back to login
                    </b-link>
                </p>
             </b-card>
        <!-- /Login v1 -->
    </div>
</div>
</template>

<script>
/* eslint-disable global-require */
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BInputGroupPrepend,
    BSpinner,
    BAlert
} from 'bootstrap-vue'
import {
    required,
    email
} from '@validations'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'
export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCard,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        Cleave,
        BInputGroupPrepend,
        BAlert
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            isLoading: false,
            status: '',
            password: '',
            phone: '',
            options: {
                phone: {
                    phone: true,
                    phoneRegionCode: 'KE',
                },
            },
            countryCode: "254",
            countryCodeOptions: [{
                    value: '254',
                    text: 'KE (+254)'
                },
                {
                    value: '233',
                    text: 'GH (+233)'
                },
            ],
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            errorMessage: "",
        }
    },
     mounted() {
        let vm  = this
        vm.getCountries()
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/authImage.png')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    directives: {
        heightFade,
        Ripple,
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        validationForm() {

            this.$refs.resetPassword.validate().then(success => {
                if (success) {
                    let vm = this
                    vm.isLoading = true
                    $('#resetPWD').html('Please Wait');
                    $.post({
                        url: store.state.rootUrl + 'account/v1/forget_password',
                        type: "post",
                        async: true,
                        crossDomain: true,
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + store.state.accessToken
                        },
                        contentType: 'application/json',
                        processData: false,
                        dataType: 'json',
                        data: JSON.stringify({
                            user_name: vm.phone,
                            country_code: vm.countryCode,
                        }),
                        success: function (response, status, jQxhr) {
                            vm.isLoading = false
                            $('#resetPWD').html(' Reset Password');
                            if (response.data.code != 200) {
                                vm.dismissCountDown = vm.dismissSecs
                                vm.showDismissibleAlert = true
                                vm.errorMessage = response.data.message
                                if (response.data.code == 121) {
                                    vm.showResendActivation = true
                                }

                            } else {
                              vm.$cookies.set("username", vm.phone,"900s");
                              vm.$cookies.set("country_code", vm.countryCode,"900s");
                                vm.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'EditIcon',
                                        variant: 'success',
                                    },
                                })
                                vm.$router.push({
                                    name: 'verification'
                                });

                            }

                        },
                        error: function (jQxhr, status, error) {
                            vm.isLoading = false
                            vm.dismissCountDown = vm.dismissSecs
                            $('#resetPWD').html(' Reset Password');
                            var errorMessage = jQxhr.responseJSON.errors[0];
                            var statustext = jQxhr.responseJSON.message;
                            vm.showDismissibleAlert = true;
                            $('#login').text('login')
                            if (!statustext) {
                                vm.errorMessage = "An error occurred. Try again later.";
                            } else {
                                vm.errorMessage = statustext + " " + errorMessage;
                            }
                        }
                    });
                }
            })
        },
         getCountries() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/countries',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + store.state.accessToken
                },
                success: function (response, status, jQxhr) {
                    
                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.countries = []
                    } else {
                        let result = response.data.data;
                       
                       for (const [key, value] of Object.entries(result)) {
                            console.log(`${key}: ${value[0]}`);
                            var country =
                                {
                                    value : `${key}`,
                                    text: `${value[0]}` +" (+"+`${key}`+")"

                                }
                                vm.countryCodeOptions.push(country)
                        }
                       
                        

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.countries = []
                }
            });
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
